import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FcOrgUnit, FcMms, FcTreeStructure, FcPortraitMode, FcDataSheet } from 'react-icons/fc';

const Timeline = () => {
  return (
    <>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#ff9800', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid #ff9800' }}
          date="2022 - Present"
          iconStyle={{ background: '#ff9800', color: '#fff' }}
          icon={<FcTreeStructure />}
        >
          <h3 className="vertical-timeline-element-title">Full Stack Engineer - Coinoxs</h3>
          <h4 className="vertical-timeline-element-subtitle">Istanbul, Turkey</h4>
          <p>
            Python3, Javascript, Typescript, Node.js, Express, AWS DynamoDB, AWS Lambda, AWS API Gateway, AWS S3.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: '7px solid #ffffff' }}
          date="2020 - 2022"
          iconStyle={{ background: '#a3b3bc', color: '#fff' }}
          icon={<FcTreeStructure />}
        >
          <h3 className="vertical-timeline-element-title" style={{ color: "black" }}>Senior Software Engineer - OSG Pro</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: "black" }}>Istanbul, Turkey</h4>
          <p style={{ color: "black" }}>
            Backend Developer, Fullstack Developer, DevOps Engineer, and Cloud Architect.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2020 / Jun - 2020 / Dec"
          iconStyle={{ background: '#a3b3bc', color: '#fff' }}
          icon={<FcOrgUnit />}
        >
          <h3 style={{ color: "black" }} className="vertical-timeline-element-title">Software Engineer, IT Support Specialist - SAI GROUP</h3>
          <h4 style={{ color: "black" }} className="vertical-timeline-element-subtitle">Istanbul, Turkey</h4>
          <p style={{ color: "black" }}>
            IT System Support Specialist, and Fullstack Developer.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2017 - 2020"
          iconStyle={{ background: '#a3b3bc', color: '#fff' }}
          icon={<FcPortraitMode />}
        >
          <h3 style={{ color: "black" }} className="vertical-timeline-element-title">Private Tutor</h3>
          <h4 style={{ color: "black" }} className="vertical-timeline-element-subtitle">Istanbul, Turkey</h4>
          <p style={{ color: "black" }}>
            Data Structures and Algorithms, Computer Science, C++, Python tutoring.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </>
  )
}

export default Timeline