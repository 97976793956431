import { Avatar, Box, Container, Heading, Stack, Tag, TagLabel, Text, VStack, Grid, GridItem, Flex } from '@chakra-ui/react'
import React from 'react'

import AWSEC2 from '../assets/images/amazon-ec2-logo.png'

const Skills = () => {
    return (
        <>

            <VStack margin={5} marginBottom={50} spacing={2} textAlign="center" >
                <Heading as="h1" fontSize="4xl">
                    About Me
                </Heading>
                <Text fontSize="lg" color={'gray.500'}>
                </Text>
            </VStack>

            <Container py={5} maxW={'container.lg'}>
                <Grid
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(4, 1fr)',
                    }}
                    gap={6}>
                    <GridItem w="100%" colSpan={{ base: 1, sm: 2, md: 2 }}>
                        <Text fontSize="lg" color={'gray.500'}> I am a software engineer with 3 years of experience in web development, 1 year in data science and machine learning, I am always passionate to learn more and domiate the field as much as i can.</Text>
                    </GridItem>

                    <GridItem colSpan={{ base: 1, sm: 2, md: 2 }} w="100%">
                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://seeklogo.com/images/D/django-logo-F46C1DD95E-seeklogo.com.png'
                                size='xs'
                                name='Django'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Django</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://pbs.twimg.com/profile_images/1517584461017370624/DF3DpXUW_normal.jpg'
                                size='xs'
                                name='Python'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Python</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://www.kindpng.com/picc/m/188-1882559_python-flask-hd-png-download.png'
                                size='xs'
                                name='Flask'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Flask</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://inlab.fib.upc.edu/sites/default/files/styles/large/public/field/image/django-rest-framework.jpg'
                                size='xs'
                                name='DRF'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Django Rest Framework</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/512px-Unofficial_JavaScript_logo_2.svg.png'
                                size='xs'
                                name='Javascript'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Javascript</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/512px-Typescript_logo_2020.svg.png?20210506173343'
                                size='xs'
                                name='TypeScript'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>TypeScript</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://www.patterns.dev/img/reactjs/react-logo@3x.svg'
                                size='xs'
                                name='React'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>React</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://logowik.com/content/uploads/images/t_redux.jpg'
                                size='xs'
                                name='Redux'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Redux</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://logowik.com/content/uploads/images/t_redux.jpg'
                                size='xs'
                                name='Redux'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>React-Redux</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://www.patterns.dev/img/reactjs/react-logo@3x.svg'
                                size='xs'
                                name='React Native'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>React Native</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://hendrixer.github.io/nextjs-course/44f073f9132a0459819eae6afa5b3807/next_with_bg.svg'
                                size='xs'
                                name='Next'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Next.Js</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://d12resqufghu8p.cloudfront.net/eyJidWNrZXQiOiJieWxnZS1pbWFnZXMiLCJrZXkiOiI4Mjk2MWYxMC0xMmQyLTExZWEtYWE1NC00ZDI5ZWQ0MDI5OTQuanBlZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJmaXQiOiJpbnNpZGUifSwidG9Gb3JtYXQiOiJqcGVnIn19'
                                size='xs'
                                name='JSX'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>JSX</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/512px-Unofficial_JavaScript_logo_2.svg.png'
                                size='xs'
                                name='ES6'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>ES6</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src={AWSEC2}
                                size='xs'
                                name='AWS EC2'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>AWS EC2</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://miro.medium.com/max/666/1*1A1CQ8a-vKphpDu97_U6Kw.png"
                                size='xs'
                                name='AWS S3'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>AWS S3</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://en.digitalcube.jp/wp-content/uploads/2016/03/AWS_Simple_Icons_Database_AmazonRDS.svg_-20160325070440-1024x1024.png"
                                size='xs'
                                name='AWS EDS'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>AWS RDS</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://symbols.getvecta.com/stencil_9/38_application-load-balancer.bd6924365b.svg"
                                size='xs'
                                name='AWS LB'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>AWS LB</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://seeklogo.com/images/G/google-cloud-functions-logo-AECD57BFA2-seeklogo.com.png"
                                size='xs'
                                name='GCF'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Google Cloud Functions</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://storage.googleapis.com/gweb-cloudblog-publish/images/serverless_scheduling.max-300x300.png"
                                size='xs'
                                name='GSF'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Google Schedular Functions</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://joshualevi.com/wp-content/uploads/2019/08/logo_google_domains_192px_Round_icon.jpg"
                                size='xs'
                                name='GD'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Google Domains</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Cloudflare_Logo.png/600px-Cloudflare_Logo.png?20211125225208"
                                size='xs'
                                name='Cloud Flare'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Cloudflare</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://www.freepnglogos.com/uploads/logo-mysql-png/logo-mysql-mysql-logo-png-images-are-download-crazypng-21.png"
                                size='xs'
                                name='MySQL'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>MySQL</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://www.postgresql.org/media/img/about/press/elephant.png"
                                size='xs'
                                name='PostgreSQL'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>PostgreSQL</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://www.seekpng.com/png/detail/117-1177155_mongodb-logo-mongodb-logo-transparent.png"
                                size='xs'
                                name='MongoDB'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>MongoDB</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://assets.ubuntu.com/v1/29985a98-ubuntu-logo32.png"
                                size='xs'
                                name='Ubuntu'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>Ubuntu</TagLabel>
                        </Tag>

                        <Tag variant="outline" size='lg' colorScheme='teal' borderRadius='full' ml={2} mt={2}>
                            <Avatar
                                src="https://logodownload.org/wp-content/uploads/2015/05/cpanel-logo-0.png"
                                size='xs'
                                name='cPanel'
                                ml={-1}
                                mr={2}
                            />
                            <TagLabel>cPanel</TagLabel>
                        </Tag>
                    </GridItem>
                </Grid>
            </Container>
        </>
    )
}

export default Skills