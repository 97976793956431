import { ReactElement } from 'react';
import { Box, Icon, Text, Stack, Flex } from '@chakra-ui/react';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Service({ icon, title, body, iconColor }) {
  return (
    <Box p={4}>
      <Feature
        icon={<Icon as={icon} w={10} h={10} color={iconColor} />}
        title={title}
        text={
          body
        }
      />
    </Box>
  );
}