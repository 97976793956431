import { Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Service from '../components/Service'
import { MdOutlineWeb } from 'react-icons/md'
import { BiMobileAlt } from 'react-icons/bi'
import { GrHostMaintenance } from 'react-icons/gr'
import { AiOutlineDatabase } from 'react-icons/ai'
import { BsClipboardData } from 'react-icons/bs'

const Services = () => {
  return (
    <>

      <VStack spacing={2} textAlign="center" margin={5}>
        <Heading as="h1" fontSize="4xl">
          Serivces
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Main services based on my skills and experience
        </Text>
      </VStack>
      <div className="container">
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Service title="Web Development" body="Exclusive design for your business, boring tasks automation, SEO, and accessbilitiy." icon={MdOutlineWeb} iconColor={"#48bb78"} />
          <Service title="Mobile Development" body="Professionally made UI for your app on both android and ios platforms." icon={BiMobileAlt} iconColor={"#2a4365"} />
          <Service title="Hosting / Server Administration" body="Domain hosting, database hosting, caching, attack preventions, and load balancing." icon={GrHostMaintenance} iconColor={"#48bb78"} />
          <Service title="Database Management" body="Boost data integration, storage optimization, secure, and high performance." icon={AiOutlineDatabase} iconColor={"#90cdf4"} />
          <Service title="Data Science" body="Data structuring, processing, and data analysis." icon={BsClipboardData} iconColor={"#48bb78"} />
        </SimpleGrid>
      </div>
    </>
  )
}

export default Services