// import CallToActionWithAnnotation from './components/Hero';
// import NavigationBar from './components/NavigationBar';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Services from './pages/Services';
import Experince from './pages/Experince';
import Contact from './pages/Contact';
import Skills from './pages/Skills';
import LandingPage from './pages/LandingPage';
import { useColorMode } from '@chakra-ui/react';

function App() {

  // const { colorMode, toggleColorMode } = useColorMode('Dark');
  return (
    <div className="App">
      <Router>
        {/* <NavigationBar /> */}
        {/* <div className='container'> */}
        <Route exact path='/' component={LandingPage} />
        <Route path='/services' component={Services} />
        <Route path='/experience' component={Experince} />
        <Route path='/skills' component={Skills} />
        <Route path='/contact' component={Contact} />
        {/* </div> */}
      </Router>
    </div>
  );
}

export default App;
