import { Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Timeline from '../components/Timeline'

const Experince = () => {
  return (
    <>
      <VStack spacing={2} textAlign="center" margin={5}>
        <Heading as="h1" fontSize="4xl">
          Experience
        </Heading>
        {/* <Text fontSize="lg" color={'gray.500'}>
            Main services based on my skills and experience
            </Text> */}
        <Timeline />
      </VStack>
    </>
  )
}

export default Experince