import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    VStack,
    Link,
    Divider,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import {
    IoLogoReact,
} from 'react-icons/io5';

import { SiDjango, SiAmazonaws, SiTypescript } from 'react-icons/si';
import { DiOpensource, DiJavascript1 } from 'react-icons/di';
import montania from "../assets/images/montania.png";
import djreact from '../assets/images/django_react.png';
import cli from '../assets/images/cli.jpg';

interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function Projects() {
    return (
        <Container maxW={'5xl'} py={12}>

            <VStack spacing={2} textAlign="center" margin={5}>
                <Heading as="h1" fontSize="4xl">
                    Projects / Portfolio
                </Heading>
            </VStack>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'0.8rem'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        Fullstack
                    </Text>
                    <Heading><Link target='_blank' href="https://themenaacademy.com">MENA Academy</Link></Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        Providing surgeons the opportunity to enhance their surgical technique and knowledge through safe and effective training on the use of Advanced Bariatric Techniques.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <Feature
                            icon={
                                <Icon as={SiDjango} color={'green.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Django'}
                        />
                        <Feature
                            icon={<Icon as={IoLogoReact} color={'green.500'} w={5} h={5} />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'React'}
                        />
                        <Feature
                            icon={
                                <Icon as={SiAmazonaws} color={'yellow.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'AWS'}
                        />
                    </Stack>
                </Stack>
                <Flex alignItems={"center"} justifyContent={"center"}>
                    <Image
                        rounded={'md'}
                        alt={'The Mena Academy Logo'}
                        src={
                            'https://themenaacademy.com/static/media/logo.935707d0c40b9f57c5ca.png'
                        }
                        objectFit={'fit'}
                    />
                </Flex>
            </SimpleGrid>

            <Divider marginTop={5} marginBottom={5} />

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>

                <Flex alignItems={"center"} justifyContent={"center"}>
                    <Image
                        rounded={'md'}
                        alt={'The Mena Academy Logo'}
                        src={
                            montania
                        }
                        objectFit={'fit'}
                    // maxW={'300px'}
                    // maxH={'300px'}
                    />
                </Flex>
                <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'sm'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        Fullstack
                    </Text>
                    <Heading><Link href="#">Django & React E-commerce</Link></Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        An all in one E-commerce website built with Django and React.

                        <Alert marginTop={3} marginBottom={3} status='info'>
                            <AlertIcon />
                            This is not a real website just a practice project.
                        </Alert>
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <Feature
                            icon={
                                <Icon as={SiDjango} color={'green.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Django'}
                        />
                        <Feature
                            icon={<Icon as={IoLogoReact} color={'green.500'} w={5} h={5} />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'React'}
                        />
                        <Feature
                            icon={
                                <Icon as={SiAmazonaws} color={'yellow.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'AWS'}
                        />
                    </Stack>
                </Stack>
            </SimpleGrid>

            <Divider marginTop={5} marginBottom={5} />

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'0.8rem'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        Open Source
                    </Text>
                    <Heading><Link target='_blank' href="https://github.com/faisalnazik/Django-REST-Framework-React-BoilerPlate">Django REST Framework & React Boilerplate</Link></Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        This is a setup of Authentication and Registration Integrated with React.js, Using Material UI Minimal Kit.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <Feature
                            icon={
                                <Icon as={SiDjango} color={'green.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Django'}
                        />
                        <Feature
                            icon={<Icon as={IoLogoReact} color={'green.500'} w={5} h={5} />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'React'}
                        />
                        <Feature
                            icon={
                                <Icon as={DiOpensource} color={'yellow.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Open Source'}
                        />
                    </Stack>
                </Stack>
                <Flex alignItems={"center"} justifyContent={"center"}>
                    <Image
                        rounded={'md'}
                        alt={'Django REST Framework & React Boilerplate'}
                        src={
                            djreact
                        }
                        objectFit={'fit'}
                    />
                </Flex>
            </SimpleGrid>

            <Divider marginTop={5} marginBottom={5} />

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>

                <Flex alignItems={"center"} justifyContent={"center"}>
                    <Image
                        rounded={'md'}
                        alt={'CLI made with Typescript and compiled to Javascript'}
                        src={
                            cli
                        }
                        objectFit={'fit'}

                    />
                </Flex>
                <Stack spacing={4}>
                    <Stack className="Tags_cli" style={{ flexDirection: 'row' }}>
                        <Text
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize={'sm'}
                            bg={useColorModeValue('blue.50', 'blue.900')}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}>
                            Open Source
                        </Text>
                        <Text
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize={'sm'}
                            bg={useColorModeValue('blue.50', 'blue.900')}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}
                            marginTop={0}
                        >
                            CLI
                        </Text>
                    </Stack>
                    <Heading><Link target="_blank" href="https://www.npmjs.com/package/eyad_cli">CLI made with Typescript and compiled to Javascript</Link></Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        This is a Javascript CLI made with Typescript to code on the go. This project is still in development and you can use it for educational purposes.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <Feature
                            icon={
                                <Icon as={SiTypescript} color={'green.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Typescript'}
                        />
                        <Feature
                            icon={<Icon as={IoLogoReact} color={'green.500'} w={5} h={5} />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'React'}
                        />
                        <Feature
                            icon={
                                <Icon as={DiJavascript1} color={'yellow.500'} w={5} h={5} />
                            }
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'Javascript'}
                        />
                    </Stack>
                </Stack>
            </SimpleGrid>

        </Container>
    );
}